import React, { FunctionComponent } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import {
  pad,
  miniPad,
  topText,
  textGroup,
  textParagraph,
  textHeader,
  priceGuarantee,
  flowBottomDiv,
  helpText,
} from "./FlowBottom.module.scss";
import savingsBlackIcon from "../../../static/images/png/savings.png";
import thumbUpBlackIcon from "../../../static/images/png/thumb-up.png";
import priceGuaranteeIcon from "../../../static/images/png/mejor-precio-garantia-rotado.png"; // '../../../static/images/priceGuarantee.png';
import useLanguageStore from "../LanguageStore/LanguageStore";

type LanguageContent = {
  head1: string;
  text1: string;
  head2: string;
  text2: string;

  help1: string;
  help2: string;
  help3: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  head1: "Ofertas Inmediatas",
  text1:
    "Ahorra dinero y tiempo, Compara y elige el solucion que es mejor para ustedes.",
  head2: "Totalmente gratis",
  text2: "Nuestro servico es totalmente gratis y sin obligaciones.",
  help1: "",
  help2: "",
  help3: "",
};

content.Español = {
  head1: "Ofertas Inmediatas",
  text1:
    "Ahorra dinero y tiempo, Compara y elige el solucion que es mejor para ustedes.",
  head2: "Totalmente gratis",
  text2: "Nuestro servico es totalmente gratis y sin obligaciones.",
  help1:
    "PuntoTerminal te ayuda a encontrar el mejor solucion de pago para tu empresa.",
  help2: "Necesitas ayuda?",
  help3: "Contacta un experto de pago en: ",
};

const FlowBottom: FunctionComponent<{}> = () => {
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;
  language === "Español"
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  return (
    <div className={flowBottomDiv}>
      <div className={miniPad} />
      <div className={topText}>
        <Container>
          <div className={pad} />
          <Row>
            <Col>
              <Image
                src={savingsBlackIcon}
                alt="Imagen mostrando ahorros"
                title="Ahorra"
                roundedCircle
              />
            </Col>
            <Col>
              {" "}
              <Image
                src={thumbUpBlackIcon}
                alt="Pulgar hacia arriba"
                title="Pulgar arriba"
                roundedCircle
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className={textHeader}>{choosenLanguage.head1}</h3>
              <p className={textParagraph}>{choosenLanguage.text1}</p>
            </Col>
            <Col>
              <h3 className={textHeader}>{choosenLanguage.head2}</h3>
              <p className={textParagraph}>{choosenLanguage.text2}</p>
            </Col>
          </Row>
          <div className={pad} />
        </Container>
      </div>
      <div>
        <Container className={textGroup}>
          <Row>
            <Col xs={6} sm={6} md={3} lg={3}>
              <Image
                className={priceGuarantee}
                src={priceGuaranteeIcon}
                alt="Mejor precio Garantizado"
                title="Garantia de mejor precio"
                roundedCircle
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className={helpText}>
                <p>{choosenLanguage.help1}</p>
                <p>{choosenLanguage.help2}</p>
                <p>{choosenLanguage.help3}</p>
                <p>
                  <strong>
                    {" "}
                    <a href="tel:5642384307">56 4238 4307</a>{" "}
                  </strong>
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default FlowBottom;
